<template>
    <div id="footer">
         <img src="@/assets/images/policeBadge.png" alt="">
         <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32010602011098" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;margin-right:50px">苏公网安备 32010602011098号</p></a>
        <span
            class="record"
            @click.stop="refClick('https://beian.miit.gov.cn/#/Integrated/index')"
        >苏ICP备2021032825号</span> 
        Copyright 2023. All rights reversed. Design by no.13
    </div>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {};
    },
    methods: {
        refClick(url) {
            window.open(url);
        }
    }
};
</script>

<style scoped>
#footer {
    width: 100%;
    height: 82px;
    background: #131313;
    font-size: 16px;
    color: #6a6a6a;
    display: flex;
    align-items: center;
    justify-content: center;
}
.record {
    cursor: pointer;
    margin-right: 50px;
}
</style>
