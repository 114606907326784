<template>
    <div id="what">
        <div class="what_body">
            <div class="left">
                <img class="img" src="../assets/images/what_img.png" alt />
            </div>
            <div class="right">
                <div class="title">阿拉丁是什么</div>
                <div class="text">
                    <p>
                        阿拉丁首先是一款资讯APP，专门为企业家、高管 人群量身定制，为他们这些特定人群提供企业经营中 所需要的主题精准资讯，分为企业、家庭、个人3种类型 的资讯；企业类资讯是指与企业经营相关的资讯，比如 企业战略、商业模式等；家庭类资讯是指与家庭生活相 关的资讯，比如家庭税收、二代接班等；个人类资讯是 指与个人爱好相关的资讯，比如财经、科技、养生等；因 此，阿拉丁在当前是一款资讯APP。
                    </p>
                    <div class="btns clickBgNo" @click.stop="btnClick">立即下载</div>
                </div>
                <div class="btnTask" v-if="show" @click.stop>
                    <div class="qr-box">
                        <!-- <div>
                            <img class="img" src="../assets/images/ios.png" alt />
                            <div>iOS苹果版</div>
                        </div> -->
                        <div>
                            <img class="img" src="../assets/images/ma.png" alt />
                            <!-- <div>安卓版</div> -->
                        </div>
                    </div>
                    <div class="tips">扫码下载阿拉丁中文网</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "What",
    data() {
        return {
            show: false
        };
    },
    methods: {
        btnClick() {
            this.show = !this.show;
        },
        showHide() {
            this.show = false;
        }
    }
};
</script>

<style scoped>
#what {
    width: 100%;
    padding: 100px 0 116px;
}
.what_body {
    width: 1244px;
    margin: 0 auto;
    display: flex;
}
.what_body .left {
    width: 638px;
    height: 478px;
    flex-shrink: 0;
}
.what_body .left .img {
    width: 100%;
    height: 100%;
}
.what_body .right {
    padding-left: 74px;
    text-align: left;
    position: relative;
}
.what_body .right .title {
    position: relative;
    font-size: 60px;
    color: #333333;
    padding-left: 25px;
    margin-top: -13px;
    font-weight: bold;
}
.what_body .right .title::before {
    content: "";
    width: 16px;
    height: 53px;
    background: #116ae3;
    position: absolute;
    left: 0;
    top: 56%;
    transform: translateY(-50%);
}
.what_body .right .text {
    font-size: 22px;
    color: #333333;
    margin-top: 70px;
}
.what_body .right .text p {
    text-indent: 2em;
    line-height: 36px;
}
.what_body .right .text .btns {
    width: 150px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #116ae3;
    border-radius: 25px;
    margin-top: 60px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
}
.what_body .right .btnTask {
    width: 499px;
    height: 335px;
    background: url(../assets/images/bubble_popupBg1.png) no-repeat center;
    background-size: 100%;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    left: 47px;
    bottom: -330px;
    z-index: 2;
    padding-top: 88px;
}
.what_body .right .btnTask .ma {
    width: 128px;
    height: 128px;
    margin: 0 auto;
}
.what_body .right .btnTask .ma img {
    width: 100%;
}
.what_body .right .btnTask .tips {
    font-size: 24px;
    margin-top: 26px;
}
</style>
