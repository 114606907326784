<template>
    <div id="with">
        <div class="with_body">
            <div class="left">
                <div class="title">对企业家有什么用？</div>
                <div class="text">
                    <p>
                        企业家作为经济发展的引领者，其关注的资讯内容和普罗大众是不 尽相同的。因此，围绕企业家的主题需求，阿拉丁通过智能算法先自动检 索企业家这一角色资讯后再主动推送到APP首页，让企业家打开APP直 接就能看到与其角色相关的资讯。与此同时，阿拉丁针对企业经营涉及的 资讯还进行了模块细分，例如：商机、战略、营销、人力资源等等。在阿拉丁 呈现的资讯里面，智能算法还会提炼出企业家关注的高亮词汇，不仅可以 有基本的词语解释，同时还将有价值的关联资讯也呈现在页面中，让企业 家在阅读资讯时不仅知道“是什么”，更能知道“为什么”以及"怎么做”。
                    </p>
                    <div class="text_foot">
                        <img class="img" src="../assets/images/ma.png" alt />
                        <div class="btns" @click.stop="btnClick('show','1')">立即下载</div>
                        <div class="btns btn2" @click.stop="btnClick('show2','2')">在线咨询</div>
                        <div class="btnTask" v-if="show" @click.stop>
                            <div class="qr-box">
                                <!-- <div>
                                    <img class="img" src="../assets/images/ios.png" alt />
                                    <div>iOS苹果版</div>
                                </div> -->
                                <div>
                                    <img class="img" src="../assets/images/ma.png" alt />
                                    <!-- <div>安卓版</div> -->
                                </div>
                            </div>
                            <div class="tips">扫码下载阿拉丁中文网</div>
                        </div>
                        <div class="btnTask btnTask2" v-if="show2" @click.stop>
                            <div class="tips">联系方式：010-6280 0077</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <img class="img" src="../assets/images/with_img.png" alt />
            </div>
        </div>
        <div class="rightBg"></div>
    </div>
</template>

<script>
export default {
    name: "With",
    data() {
        return {
            show: false,
            show2: false
        };
    },
    methods: {
        showHide() {
            this.show = false;
            this.show2 = false;
        },
        btnClick(show, index) {
            if (index == 1) {
                this.show2 = false;
            } else {
                this.show = false;
            }
            this[show] = !this[show];
        }
    }
};
</script>

<style scoped>
#with {
    width: 100%;
    background: #f9f9fa;
    padding: 68px 0 104px;
    position: relative;
}
.with_body {
    width: 1200px;
    margin: 0 auto;
    display: flex;
}
.with_body .left {
    width: 682px;
    text-align: left;
    position: relative;
    padding-top: 30px;
}
.with_body .left .title {
    position: relative;
    font-size: 60px;
    color: #333333;
    padding-left: 25px;
    font-weight: bold;
}
.with_body .left .title::before {
    content: "";
    width: 16px;
    height: 53px;
    background: #116ae3;
    position: absolute;
    left: 0;
    top: 56%;
    transform: translateY(-50%);
}
.with_body .left .text {
    font-size: 22px;
    color: #333333;
    margin-top: 70px;
}
.with_body .left .text p {
    text-indent: 2em;
    line-height: 36px;
}
.with_body .left .text .text_foot {
    margin-top: 146px;
    display: inline-flex;
    align-items: center;
}
.with_body .left .text .text_foot .img {
    width: 128px;
    height: 128px;
}
.with_body .left .text .text_foot .btns {
    width: 150px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #116ae3;
    border-radius: 25px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    margin-left: 58px;
}
.with_body .left .text .text_foot .btn2 {
    margin-left: 68px;
}
.with_body .left .btnTask {
    width: 499px;
    height: 335px;
    background: url(../assets/images/bubble_popupBg1.png) no-repeat center;
    background-size: 100%;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    left: 158px;
    bottom: -285px;
    z-index: 2;
    padding-top: 88px;
}
.with_body .left .btnTask2 {
    width: 453px;
    height: 205px;
    background: url(../assets/images/bubble_popupBg2.png) no-repeat center;
    background-size: 100%;
    left: 376px;
    bottom: -154px;
}
.with_body .left .btnTask .ma {
    width: 128px;
    height: 128px;
    margin: 0 auto;
}
.with_body .left .btnTask .ma img {
    width: 100%;
}
.with_body .left .btnTask .tips {
    font-size: 24px;
    margin-top: 26px;
}
.with_body .left .btnTask2 .tips {
    margin-top: 10px;
}
.with_body .right {
    width: 718px;
    height: 787px;
    padding-left: 15px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    background: #f9f9fa;
}
img{
    height: 100%;
}
.rightBg {
    width: calc(100% - 1300px);
    height: 787px;
    background: rgba(17, 106, 227, 0.1);
    position: absolute;
    right: 0;
    top: 68px;
}
</style>
